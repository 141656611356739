export const dbMapping = {
  BasicDetails: 'case_employee_data',
  Address: 'case_address_bgv',
  DigitalAddress: 'case_digitalAddress_bgv',
  CreditCheck: 'case_creditCheck_bgv',
  CibilCheck: 'case_cibilCheck_bgv',
  Criminal: 'case_criminal_bgv',
  DatabaseCheck: 'case_globalDB_bgv',
  DrugTest: 'case_drugTest_bgv',
  Education: 'case_education_bgv',
  Employment: 'case_employment_bgv',
  Identification: 'case_id_bgv',
  GapVerification: 'case_gap_bgv',
  UanCheck: 'case_uan_bgv',
  Reference: 'case_reference_bgv',
  CompanyCheck: 'case_company_bgv',
  CvValidation: 'case_cv_bgv',
  DirectorshipCheck: 'case_directorship_bgv',
  PoliceVerification: 'case_police_bgv',
  SocialMedia: 'case_socialmedia_bgv'
};

export const dbMappingReverse = {
  case_employee_data: 'BasicDetails',
  case_address_bgv: 'Address',
  case_creditCheck_bgv: 'CreditCheck',
  case_cibilCheck_bgv: 'CibilCheck',
  case_criminal_bgv: 'Criminal',
  case_globalDB_bgv: 'DatabaseCheck',
  case_drugTest_bgv: 'DrugTest',
  case_education_bgv: 'Education',
  case_employment_bgv: 'Employment',
  case_id_bgv: 'Identification',
  case_gap_bgv: 'GapVerification',
  case_reference_bgv: 'Reference',
  case_company_bgv: 'CompanyCheck',
  case_cv_bgv: 'CvValidation',
  case_directorship_bgv: 'DirectorshipCheck',
  case_police_bgv: 'PoliceVerification',
  case_socialmedia_bgv: 'SocialMedia'
};

export const InvoiceReportMapping = {
  case_address_bgv_1: 'Address 1',
  case_address_bgv_2: 'Address 2',
  case_address_bgv_3: 'Address 3',
  case_address_bgv_4: 'Address 4',

  case_digitalAddress_bgv_1: 'Digital Address 1',
  case_digitalAddress_bgv_2: 'Digital Address 2',
  case_digitalAddress_bgv_3: 'Digital Address 3',
  case_digitalAddress_bgv_4: 'Digital Address 4',
  
  case_creditCheck_bgv: 'CreditCheck',
  case_cibilCheck_bgv: 'CibilCheck',
  
  case_criminal_bgv_1: 'Criminal 1',
  case_criminal_bgv_2: 'Criminal 2',
  
  case_globalDB_bgv: 'DatabaseCheck',
  case_drugTest_bgv: 'DrugTest',
  
  case_education_bgv_1: 'Education 1',
  case_education_bgv_2: 'Education 2',
  case_education_bgv_3: 'Education 3',
  
  case_employment_bgv_1: 'Employment 1',
  case_employment_bgv_2: 'Employment 2',
  case_employment_bgv_3: 'Employment 3',
  
  case_id_bgv_1: 'Identification 1',
  case_id_bgv_2: 'Identification 2',
  case_id_bgv_3: 'Identification 3',
  case_id_bgv_4: 'Identification 4',
  case_id_bgv_5: 'Identification 5',
  case_id_bgv_6: 'Identification 6',
  
  case_gap_bgv: 'GapVerification',
  
  case_reference_bgv_1: 'Reference 1',
  case_reference_bgv_2: 'Reference 2',
  case_reference_bgv_3: 'Reference 3',
  
  case_company_bgv: 'CompanyCheck',
  case_cv_bgv: 'CvValidation',
  case_directorship_bgv: 'DirectorshipCheck',
  case_police_bgv: 'PoliceVerification',
  case_socialmedia_bgv: 'SocialMedia'
};

// Label
export const bgvStatus = {
  Awaiting: 'Awaiting',
  Wip: 'Work in progress',
  UnableToVerify: 'Unable to verify',
  Discrepancy: 'Discrepancy',
  Completed: 'Completed',
  Insufficient: 'Insufficiency',
  BgvStop: 'BGV stop',
  NotProvided: 'Not provided',
  PartiallyCompleted: 'Partially completed',
};

// Label v2
export const bgvStatusV2 = {
  Awaiting: 'Awaiting',
  Wip: 'WIP',
  UnableToVerify: 'UnableToVerify',
  Discrepancy: 'Discrepancy',
  Completed: 'Completed',
  Insufficient: 'InSufficient',
  BgvStop: 'BgvStop'
};

// Values
export const bgvStatusValues = {
  Awaiting: 'Awaiting',
  Wip: 'WIP',
  UnableToVerify: 'UnableToVerify',
  Discrepancy: 'Discrepancy',
  Completed: 'Completed',
  Insufficient: 'InSufficient',
  BgvStop: 'BgvStop',
  NotProvided: 'NotProvided',
  PartiallyCompleted: 'PartiallyCompleted',
};

// Label
export const reportStatusOptions = {
  Awaiting: 'Awaiting',
  interimQcReview: 'Interim QC Review',
  interimQcApproved: 'Interim QC Approved',
  interimQcRejected: 'Interim QC Rejected',
  finalQcReview: 'Final QC Review',
  finalQcApproved: 'Final QC Approved',
  finalQcRejected: 'Final QC Rejected'
};

// Values
export const reportStatusOptionsValues = {
  totalInterimReports: 'Awaiting',
  totalFinalReports: 'WIP',
  approvedInterimReports: 'Unable to verify',
  approvedFinalReports: 'Discrepancy',
  rejectedInterimReports: 'Completed',
  rejectedFinalReports: 'InSufficient'
};

export const addressTypesDbMapping = {
  PhysicalOnly: 1,
  DigitalOnly: 2,
  Both: 3
};

export const davStatusLabels = {
  Awaiting: 'Awaiting',
  Wip: 'Work in progress',
  Submitted: 'Submitted',
  ReInitiated: 'Re Initiated',
  Completed: 'Completed',
  UnableToVerify: 'Unable to verify',
  Discrepancy: 'Discrepancy',
  Insufficient: 'Insufficiency',
  Saved: 'Saved'
};

export const davStatusValues = {
  Awaiting: 'Awaiting',
  Wip: 'WIP',
  Submitted: 'Submitted',
  Completed: 'Completed',
  ReInitiated: 'ReInitiated',
  UnableToVerify: 'UnableToVerify',
  Discrepancy: 'Discrepancy',
  Insufficient: 'InSufficient'
};

export const davAddressTypes = {
  Permanent: 'permanentAddress',
  Current: 'currentAddress',
  IntermediateOne: 'intermediateAddressOne',
  IntermediateTwo: 'intermediateAddressTwo'
};
