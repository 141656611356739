const cstSupMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'CST SUPERVISOR LOGIN',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'cst-sup-dashboard',
          title: 'CST Supervisor Dashboard',
          type: 'item',
          icon: 'feather icon-bar-chart',
          url: '/cst-supervisor-portal/cst-sup-dashboard'
        },
        {
          id: 'cst-sup-clientdetails',
          title: 'Clients',
          type: 'item',
          icon: 'feather icon-list',
          url: '/cst-supervisor-portal/cst-sup-clientdetails'
        },
        {
          id: 'cst-sup-userdetails',
          title: 'CST Users',
          type: 'item',
          icon: 'feather icon-users',
          url: '/cst-supervisor-portal/cst-sup-userdetails'
        }
      ]
    }
  ]
};

export default cstSupMenuItems;
