const cstMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'CST LOGIN',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'cst-dashboard',
          title: 'CST Dashboard',
          type: 'item',
          icon: 'feather icon-bar-chart',
          url: '/cst-portal/cst-dashboard'
        },
        {
          id: 'cst-clientdetails',
          title: 'Clients',
          type: 'item',
          icon: 'feather icon-list',
          url: '/cst-portal/cst-clientdetails'
        }
      ]
    }
  ]
};

export default cstMenuItems;
