const opsMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'OPERATIONS LOGIN',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'ops-dashboard',
          title: 'Operations Dashboard',
          type: 'item',
          icon: 'feather icon-bar-chart',
          url: '/operations-portal/ops-dashboard'
        },
        {
          id: 'ops-casedetails',
          title: 'Cases',
          type: 'item',
          icon: 'feather icon-list',
          url: '/operations-portal/ops-casedetails/all_cases'
        }
      ]
    }
  ]
};

export default opsMenuItems;
