import axios from 'axios'
import axiosRetry from 'axios-retry'

import { errorMsg } from '../components/Shared/Notification/ToastNotification';
import { SessionStorage } from '../util/SessionStorage'
import { formatData, isEmptyObject } from '../util/utils';
import { dynamicUrl } from "../helper/dynamicUrls";

export const axiosInstance = axios.create({
    baseURL: `${dynamicUrl}`,
    headers: { 'Authorization': SessionStorage.getItem("user_jwt") },
    timeout: 10000
});

const retryDelay = (retryNumber = 0) => {
    const seconds = Math.pow(2, retryNumber) * 1000;
    const randomMs = 1000 * Math.random();
    return seconds + randomMs;
};

axiosRetry(axiosInstance, {
    retries: 2,
    retryDelay,
    // retry on Network Error & 5xx responses
    retryCondition: axiosRetry.isRetryableError,
});

const DEBUG = process.env.NODE_ENV === "development";

function errorResponseHandler(error) {
    var element = document.getElementById("overlay");
    if (element && element.parentNode) {
        element.parentNode.removeChild(element);
    }

    if (DEBUG) { console.error(`Error: ${formatData(error)}`); }

    if (error.response && error.response.data) {
        errorMsg(error.response.data);
    }
    else if (error.message) {
        errorMsg(error.message);
    } else {
        errorMsg("Please contact message... 9999999999");
    }
    return error;
}

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    // config.headers.test = 'I am only a header!'; // EX: Add jwt token

    let session_id = SessionStorage.getItem('session_id');
    let user_id = SessionStorage.getItem('user_id');

    if (isEmptyObject(config.data)) {
        config.data = {};
    }
    if (session_id)
        config.data["session_id"] = session_id;

    if (user_id)
        config.data["user_id"] = user_id;

    if (DEBUG) { console.info(`Request: ${formatData(config)}`); }

    return config;
}, errorResponseHandler);

// apply interceptor on response
axiosInstance.interceptors.response.use(function (response) {
    if (DEBUG) { console.info(`Response: ${formatData(response)}`); }

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, errorResponseHandler);

export const getAPICall = async (url, data) => await axiosInstance.get(url, data);
export const postAPICall = async (url, data) => await axiosInstance.post(url, data);
export const putAPICall = async (url, data) => await axiosInstance.put(url, data);
export const deleteAPICall = async (url, data) => await axiosInstance.delete(url, data);