const salesMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'SALES LOGIN',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'sales-clientdetails',
          title: 'Client Details',
          type: 'item',
          icon: 'feather icon-package',
          url: '/sales-portal/sales-clientdetails'
        }
      ]
    },
  ]
};

export default salesMenuItems;