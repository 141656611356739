const digitalAddressMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'DIGITAL ADDRESS VERIFICATION',
      type: 'group',
      icon: 'icon-monitor',
      // children: [
      //   {
      //     id: 'digital-address-home',
      //     title: 'Digital Address',
      //     type: 'item',
      //     icon: 'feather icon-list',
      //     url: '/digitalAddressVerification'
      //   }
      // ]
    },
  ]
};

export default digitalAddressMenuItems;