import MESSAGES from '../../../../helper/messages';

export const bgvAlerts = {
  compInsertSuccess: {
    title: MESSAGES.TTTLES.Goodjob,
    type: 'success',
    text: 'Component ' + MESSAGES.SUCCESS.ComponentAdded
  },
  emailSentSuccessfully: {
    title: MESSAGES.TTTLES.Goodjob,
    type: 'success',
    text: 'Component ' + MESSAGES.SUCCESS.EmailSentSuccessfully
  },
  compUpdateSuccess: {
    title: MESSAGES.TTTLES.Goodjob,
    type: 'success',
    text: 'Component ' + MESSAGES.SUCCESS.ComponentEdited
  },
  compInsertError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.ComponentAdding
  },
  sendEmailError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.ErrorInSendingEmail
  },
  compUpdateError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.ComponentEditing
  },
  qcApprovedError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.QcApproveError
  },
  qcRejectedError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.QcRejectError
  },
  verificationSuccess: {
    title: MESSAGES.TTTLES.Goodjob,
    type: 'success',
    text: MESSAGES.SUCCESS.VerificationDone
  },
  verificationFailed: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.VerificationFailed
  },
  qcApproveSuccess: {
    title: MESSAGES.TTTLES.Goodjob,
    type: 'success',
    text: MESSAGES.SUCCESS.QcApproved
  },
  qcRejectSuccess: {
    title: MESSAGES.TTTLES.Goodjob,
    type: 'success',
    text: MESSAGES.SUCCESS.QcRejected
  },
  reportGenerationError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.ReportGenerationError
  },
  reportGenerationSuccess: {
    title: MESSAGES.TTTLES.Goodjob,
    type: 'success',
    text: MESSAGES.SUCCESS.ReportGenerationSuccess
  },
  inappropriateAge: {
    title: MESSAGES.TTTLES.Warning,
    type: 'warning',
    text: MESSAGES.WARNINGS.InappropriateAge
  },
  joinDateReleivingDateMismatch: {
    title: MESSAGES.TTTLES.Warning,
    type: 'warning',
    text: MESSAGES.WARNINGS.JoinDateReleivingDateMismatch
  },
  CaseReceivedDateAboveTheJoiningDate: {
    title: MESSAGES.TTTLES.Warning,
    type: 'warning',
    text: MESSAGES.WARNINGS.CaseReceivedDateAboveTheJoiningDate
  },
  invalidFilesPresent: {
    title: MESSAGES.TTTLES.InvalidFilesPresent,
    type: 'warning',
    text: MESSAGES.WARNINGS.InvalidFilesPresent
  },
  invalidFileSizeBulkUpload: {
    title: MESSAGES.TTTLES.InvalidFilesPresent,
    type: 'warning',
    text: MESSAGES.WARNINGS.invalidFileSizeBulkUpload
  },
  invalidFilesPresentBulkUpload: {
    title: MESSAGES.TTTLES.InvalidFilesPresent,
    type: 'warning',
    text: MESSAGES.WARNINGS.InvalidFilesPresentBulkUpload
  },
  uploadMandatoryFiles: {
    title: MESSAGES.TTTLES.UploadMandatoryFiles,
    type: 'warning',
    text: MESSAGES.WARNINGS.UploadMandatoryFiles
  },
  noFilesPresent: {
    title: MESSAGES.TTTLES.NoFilesPresent,
    type: 'warning',
    text: MESSAGES.WARNINGS.NoFilesPresent
  },
  sufficientFileUpload: {
    title: MESSAGES.TTTLES.UploadSufficientFiles,
    type: 'warning',
    text: MESSAGES.WARNINGS.verifyAndUploadSufficientFiles
  },
  requiredIdFiles: {
    title: MESSAGES.TTTLES.UploadMandatoryFiles,
    type: 'warning',
    text: MESSAGES.WARNINGS.NoFilesPresentIdentification
  },
  noFilesPresentBulkUpload: {
    title: MESSAGES.TTTLES.Warning,
    type: 'warning',
    text: MESSAGES.WARNINGS.NoFilesPresentBulkUpload
  },
  forgotPassword: {
    title: MESSAGES.SUCCESS.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.PleaseCheckYourEmailForTheOTP
  },
  passwordChanged: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.PasswordChanged
  },
  OtpValidated: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.OtpValidated
  },
  SomethingWentWrong: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.SomethingWentWrong
  },
  incorrectOTP: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.IncorrectOtp
  },
  OverrideInvalid: {
    title: MESSAGES.TTTLES.Warning,
    type: 'warning',
    text: MESSAGES.WARNINGS.OverrideInvalid
  },
  OverrideError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.OverrideError
  },
  OverrideSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.OverrideSuccess
  },
  ConfirmOverride: {
    title: MESSAGES.TTTLES.AreYouSure,
    text: MESSAGES.INFO.OVERRIDE_ADDRESS_TYPE
  },
  InitiateDAVError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.InitiateDAVError
  },
  InitiateNoAddressSelected: {
    title: MESSAGES.TTTLES.Warning,
    type: 'warning',
    text: MESSAGES.WARNINGS.InitiateNoAddressSelected
  },
  InitiateDAVSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.InitiateDAVSuccess
  },
  ReInitiateDAVError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.ReInitiateDAVError
  },
  ReInitiateDAVSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.ReInitiateDAVSuccess
  },
  ReSendDAVError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.ReSendDAVError
  },
  ReSendDAVSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.ReSendDAVSuccess
  },
  DAVSubmitSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.DAVSubmitSuccess
  },
  DAVSubmitError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.DAVSubmitError
  },
  DAVSaveSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.DAVSaveSuccess
  },
  DAVSaveError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.DAVSaveError
  },
  StatusChangeSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.StatusChangeSuccess
  },
  StatusChangeError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.StatusChangeError
  },
  AddressSaveError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.AddressSaveError
  },
  AddressSaveSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.AddressSaveSuccess
  },
  FetchInvoicesError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.FetchInvoicesError
  },
  FetchInvoiceError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.FetchInvoiceError
  },
  PricingUpdateError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.PricingUpdateError
  },
  PricingUpdateSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.PricingUpdateSuccess
  },
  InvoiceGenerationError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.InvoiceGenerationError
  },
  InvoiceGenerationSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.InvoiceGenerationSuccess
  },
  InvoiceReportGenerationError: {
    title: MESSAGES.TTTLES.Sorry,
    type: 'error',
    text: MESSAGES.ERROR.InvoiceReportGenerationError
  },
  InvoiceReportGenerationSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.InvoiceReportGenerationSuccess
  },
  clientEmpBasicSuccess: {
    title: MESSAGES.TTTLES.Success,
    type: 'success',
    text: MESSAGES.SUCCESS.clientEmpBasicSuccess
  },
  deleteComponentsSuccess: {
    title: MESSAGES.TTTLES.Deleted,
    type: 'success',
    text: MESSAGES.SUCCESS.deleteComponentsSuccess
  },
};
