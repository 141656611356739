import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { FirebaseProvider } from './contexts/FirebaseContext';
//import { JWTProvider } from "./contexts/JWTContext";
//import { Auth0Provider } from "./contexts/Auth0Context";
import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import { userCategoryAction } from './store/userCategoryAction';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.process = {
      ...window.process,
    };

    const arrow_keys_handler = function (e) {
      switch (e.code) {
        // case "ArrowUp": case "ArrowDown": case "ArrowLeft": case "ArrowRight":
        // case "Space": e.preventDefault(); break;
        case "ArrowUp": case "ArrowDown":
          e.preventDefault(); break;
        default: break; // do not block other keys
      }
    };
    window.addEventListener("keydown", arrow_keys_handler, false);

    // Decoading JWT
    function parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      dispatch(userCategoryAction(JSON.parse(jsonPayload).user_category));
      return JSON.parse(jsonPayload);
    };

    sessionStorage.getItem('user_jwt') && parseJwt(sessionStorage.getItem('user_jwt'));
  }, []);

  return (
    <React.Fragment>
      <Router basename={BASENAME}>
        <FirebaseProvider>{renderRoutes(routes)}</FirebaseProvider>
      </Router>
    </React.Fragment>
  );
};

export default App;
