const adminMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'Admin Login',
      type: 'group',
      icon: 'icon-monitor',
      url: '/admin-portal/admin-dashboard',
      children: [
        {
          id: 'admin-dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-bar-chart',
          url: '/admin-portal/admin-dashboard'
        },
        {
          id: 'admin-clientdetails',
          title: 'Client Details',
          type: 'item',
          icon: 'feather icon-list',
          url: '/admin-portal/admin-clientdetails'
        },
        {
          id: 'admin-userdetails',
          title: 'User Details',
          type: 'item',
          icon: 'feather icon-users',
          url: '/admin-portal/admin-userdetails'
        },
        {
          id: 'admin-vendordetails',
          title: 'Vendor Details',
          type: 'item',
          icon: 'feather icon-tag',
          url: '/admin-portal/admin-vendordetails'
        }
      ]
    }
  ]
};

export default adminMenuItems;
