const opsSupMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'OPERATION SUPERVISOR LOGIN',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'ops-sup-dashboard',
          title: 'Ops Supervisor Dashboard',
          type: 'item',
          icon: 'feather icon-bar-chart',
          url: '/operations-supervisor-portal/ops-sup-dashboard'
        },
        {
          id: 'ops-sup-clientdetails',
          title: 'Clients',
          type: 'item',
          icon: 'feather icon-list',
          url: '/operations-supervisor-portal/ops-sup-clientdetails'
        },
        {
          id: 'ops-sup-userdetails',
          title: 'Ops Users',
          type: 'item',
          icon: 'feather icon-users',
          url: '/operations-supervisor-portal/ops-sup-userdetails'
        }
      ]
    }
  ]
};

export default opsSupMenuItems;
