import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import aadharNumberReducer from './aadharNumberReducer';
import caseDetailsReducer from './caseDetailsReducer';
import userCategoryReducer from './userCategoryReducer';

const reducers = combineReducers({
  form: formReducer,
  userCategory: userCategoryReducer,
  aadharNumber: aadharNumberReducer,
  caseDetailsData: caseDetailsReducer 
});

export default reducers;
