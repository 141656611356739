const qcMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'Quality Control',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'qc-dashboard',
          title: 'QC Dashboard',
          type: 'item',
          icon: 'feather icon-bar-chart',
          url: '/qc-portal/qc-dashboard'
        },
        // {
        //   id: 'qc-casedetails',
        //   title: 'Reports',
        //   type: 'item',
        //   icon: 'feather icon-package',
        //   url: '/qc-portal/qc-casedetails'
        // },
        {
          id: 'qc-clientdetails',
          title: 'Clients',
          type: 'item',
          icon: 'feather icon-list',
          url: '/qc-portal/qc-clientdetails'
        }
      ]
    }
  ]
};

export default qcMenuItems;
