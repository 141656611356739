import { decodeJWT } from '../util/utils';
import { SessionStorage } from '../util/SessionStorage';

const { user_client_id } = SessionStorage.getItem('user_jwt') ? decodeJWT(SessionStorage.getItem('user_jwt')) : '';

const clientMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'CLIENT LOGIN',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'ca-dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-bar-chart',
          url: '/client-portal/ca-dashboard'
        },
        {
          id: 'ca-createcase',
          title: 'Case Upload',
          type: 'item',
          icon: 'feather icon-plus-circle',
          url: '/client-portal/ca-addbgv'
        },
        {
          id: 'ca-upload',
          title: 'Bulk Upload',
          type: 'item',
          icon: 'feather icon-upload',
          url: '/client-portal/ca-upload'
        },
        {
          id: 'ca-casedetails',
          title: 'Case Details',
          type: 'item',
          icon: 'feather icon-eye',
          url: `/client-portal/ca-casedetails/${user_client_id}/all_cases`
        },
        {
          id: 'ca-userdetails',
          title: 'Users',
          type: 'item',
          icon: 'feather icon-users',
          url: '/client-portal/ca-userdetails'
        }
      ]
    }
  ]
};

export default clientMenuItems;
