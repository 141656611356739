import { decodeJWT } from '../util/utils';
import { SessionStorage } from '../util/SessionStorage';

const { user_client_id } = SessionStorage.getItem('user_jwt') ? decodeJWT(SessionStorage.getItem('user_jwt')) : '';

const clientEmployeeMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'EMPLOYEE LOGIN',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'ce-createcase',
          title: 'Case Upload',
          type: 'item',
          icon: 'feather icon-plus-circle',
          url: '/client-employee-portal/ce-addbgv'
        }
      ]
    }
  ]
};

export default clientEmployeeMenuItems;
