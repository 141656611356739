import { AADHAR_NUMBER } from "./actions";

const initialState = '';

const aadharNumberReducer = (state = initialState, action) => {
    switch (action.type) {
        case AADHAR_NUMBER: {
            return {state: action.payload};
        }
        default: {
            return { state };
        }
    }
}
export default aadharNumberReducer