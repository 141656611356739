const accountsMenuItems = {
  items: [
    {
      id: 'admin-panel',
      title: 'ACCOUNTS',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'generate-invoice',
          title: 'Generate Invoice',
          type: 'item',
          icon: 'feather icon-list',
          url: '/accounts-portal/generate-invoice'
        },
        {
          id: 'invoice-overview',
          title: 'Invoice Overview',
          type: 'item',
          icon: 'feather icon-eye',
          url: '/accounts-portal/invoice-overview'
        }
      ]
    },
  ]
};

export default accountsMenuItems;