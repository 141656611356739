import { CATEGORY_ACTION } from './actions';

const initialState = '';

const userCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_ACTION: {
            return {state: action.payload};
        }
        default: {
            return { state };
        }
    }
}
export default userCategoryReducer