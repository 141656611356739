import { CASE_DETAILS, CASE_DETAILS_TEMP } from "./actions";

const initialState = {};

const caseDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CASE_DETAILS: {
            return {...state, ...action.payload, Id: Math.random()};
        }
        case CASE_DETAILS_TEMP: {
            return {...state, Id: Math.random()};
        }
        default: {
            return { state };
        }
    }
}
export default caseDetailsReducer