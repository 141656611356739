const MESSAGES = {
  ERROR: {
    ComponentAdding: 'Error in adding the component! Please ensure that basic details component is completed.',
    ErrorInSendingEmail: 'Error in sending email!',
    ErrorInUpdatingTag: 'Error in updating tag!',
    ComponentEditing: 'Error in editing the component',
    ComponentFetching: 'Error in fetching the component',
    FilesUploading: 'Error in uploading the files',
    FilesDownloaded: 'Error in downloading the files',
    AddingClient: 'Error in adding Client',
    AddingUser: 'Error in adding User',
    UpdatingUser: 'Error in updating User',
    DeletingUser: 'Error in deleting User',
    UpdatingClient: 'Error in updating Client',
    AssignUsers: 'Error in assigning users',
    VerificationFailed: 'Verification Failed',
    ReportApproved: 'Report approve failed',
    ReportRejected: 'Report rejection failed',
    SomethingWentWrong: 'Something went wrong',
    IncorrectOtp: 'Incorrect OTP!',
    ClientNameExists: 'Client name exists already!',
    QcVerificationError: 'Error in verifying the component',
    QcApproveError: 'Error in approving the case!',
    QcRejectError: 'Error in rejecting the case!',
    ReportGenerationError: 'Report generation failed!',
    ErrorDeletingCase: 'Error in deleting the case',
    ReportDownloadError: 'Error in downloading the report',
    CasesFetchError: 'Error in fetching the cases',
    InsuffClearedError: 'Error in clearing insufficiency!',
    OverrideError: 'Error in overriding address types!',
    InitiateDAVError: 'Error in initiating digital address verification!',
    ReInitiateDAVError: 'Error in re-initiating digital address verification!',
    ReSendDAVError: 'Error in resending SMS!',
    DAVSaveError: 'Error in saving digital address verification',
    DAVSubmitError: 'Error in submitting digital address verification',
    StatusChangeError: 'Error in updating the status',
    AddressSaveError: 'Error in saving the addresses!',
    FetchInvoicesError: 'Error in fetching the invoices!',
    FetchInvoiceError: 'Error in fetching the invoice!',
    PricingUpdateError: 'Error in saving the price!',
    InvoiceGenerationError: 'Error in generating invoice!',
    InvoiceReportGenerationError: 'Error in generating invoice report!',
    PleaseAddBasicDetails: "Please add basic details"
  },
  SUCCESS: {
    PleaseCheckYourEmailForTheOTP: 'please check your mail for the otp!',
    RegisteredSuccessfully: 'registered successfully!',
    ComponentAdded: 'added successfully!',
    EmailSentSuccessfully: 'Email sent successfully!',
    EmailSentFailure: 'Failed to send Email!',
    ComponentEdited: 'edited successfully!',
    FilesUploaded: 'Files uploaded successfully',
    FilesDownloaded: 'Files downloaded successfully',
    AddingClient: 'Added Client Successfully',
    AddingUser: 'Added User Successfully',
    UpdatingUser: 'Updated User Successfully',
    UpdatingClient: 'Updated Client Successfully',
    UpdatingTags: 'Tags Updated Successfully',
    AssignUsers: 'Users assigned successfully',
    VerificationDone: 'Verified successfully',
    ReportApproved: 'Report approved successfully',
    ReportRejected: 'Report rejected successfully',
    PasswordChanged: 'Password changed successfully',
    OtpValidated: 'OTP Validated successfully',
    CaseDeleted: 'Case deleted successfully',
    InsuffCleared: 'Email sent to Operations team!',
    OverrideSuccess: 'Address verification types overrided successfully!',
    InitiateDAVSuccess: 'Digital address verification initiated successfully!',
    ReInitiateDAVSuccess: 'Digital address verification re-initiated successfully!',
    ReSendDAVSuccess: 'SMS resent successfully!',
    DAVSaveSuccess: 'Details saved successfully!',
    DAVSubmitSuccess: 'Thank you for completing the address verification successfully!',
    StatusChangeSuccess: 'Status updated successfully!',
    ReportGenerationSuccess: 'Report generated successfully!',
    AddressSaveSuccess: 'Addresses saved successfully!',
    PricingUpdateSuccess: 'Price saved successfully!',
    InvoiceGenerationSuccess: 'Invoice generated successfully!',
    InvoiceReportGenerationSuccess: 'Invoice report generated successfully!',
    clientEmpBasicSuccess: 'Thanks for updating the basic information. Request you to update rest all the details and submit the relevant documents',
    deleteComponentsSuccess: 'Selected Components Data has been deleted.'
  },
  TTTLES: {
    Goodjob: 'Done',
    Deleted: 'Deleted!',
    Sorry: 'Sorry',
    Success: 'Success',
    AreYouSure: 'Are you sure?',
    Warning: 'Warning!',
    InvalidFilesPresent: 'Invalid Files!',
    UploadMandatoryFiles: 'Upload Mandatory Files!',
    NoFilesPresent: 'No files available!',
    UploadSufficientFiles: 'Upload sufficient files',
    UploadSufficientData: 'Upload sufficient Data'
  },
  INFO: {
    NO_CASES_MESSAGE: 'There are no cases!',
    NOT_ABLE_TO_RECOVER: 'Once deleted, you will not be able to recover this data!',
    DATA_DELETED: 'Your data has been deleted!',
    USER_DELETED: 'The user has been deleted!',
    CLIENT_DELETED: 'The client has been deleted!',
    DATA_SAFE: 'Your data is safe!',
    APPROVE_DENY_REASON: 'Reason to ',
    OVERRIDE_ADDRESS_TYPE: 'This will override the address configuration!'
  },
  VALIDATION: {
    MUST_BE_A_VALID_EMAIL_ADDRESS: 'Must be a valid email address',
    EMAIL_IS_REQUIRED: 'Email is required',
    PASSWORD_IS_REQUIRED: 'Password is required'
  },
  WARNINGS: {
    InappropriateAge: 'The employee is below 18',
    JoinDateReleivingDateMismatch: 'Releiving date should be ahead of joining date',
    CaseReceivedDateAboveTheJoiningDate: 'Case received date must be after the joining date',
    InvalidFilesPresent: 'Uploaded files should be less than 10MB. Supported formats are .png, .jpg, .jpeg, .pdf',
    invalidFileSizeBulkUpload: 'Uploaded files should be less than 15MB.',
    InvalidFilesPresentBulkUpload: 'Supported formats are .xlsx, .zip respectively',
    UploadMandatoryFiles: 'Please upload aadhar or pan card fields',
    NoFilesPresent: 'Please upload the mandatory evidences!',
    verifyAndUploadSufficientFiles: 'Please verify and upload sufficient files for verification',
    NoFilesPresentBulkUpload: 'Please upload the mandatory files!',
    NoFilesPresentIdentification: 'Please upload the mandatory aadhar and pan evidence!',
    OverrideInvalid: 'Please select an address type!',
    ConfirmOverride: '',
    DAVNoAddressSelected: 'Please select an address!',
  }
};

export default MESSAGES;
