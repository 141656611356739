const baseUrl = process.env.REACT_APP_BASE_URL;
const reportURL = process.env.REACT_APP_REPORT_URL;
// const baseUrl = 'http://localhost:3001/v1';

export const dynamicUrl = {
  login: baseUrl + '/login',
  userGoogleLogin: baseUrl + '/userGoogleLogin',
  changePassword: baseUrl + '/changePassword',
  logout: baseUrl + '/logout',
  employeeLogin: baseUrl + '/employeeLogin',
  forgotPassword: baseUrl + '/forgotPassword',
  validateForgotPasswordOtp: baseUrl + '/validateForgotPasswordOtp',
  employeeOtpValidation: baseUrl + '/employeeOtpValidation',
  fetchClientComponent: baseUrl + '/fetchClientComponent',
  insertCaseUrl: baseUrl + '/insertCases',
  insertVendor: baseUrl + '/insertVendor',
  fetchAllVendors: baseUrl + '/fetchAllVendors',
  updateVendor: baseUrl + '/updateVendor',
  deleteVendor: baseUrl + '/deleteVendor',
  fetchCases: baseUrl + '/fetchClientCases',
  fetchCaseByCaseStatus: baseUrl + '/fetchCaseByCaseStatus',
  fetchCaseByReportStatus: baseUrl + '/fetchCaseByReportStatus',
  getAllCaseDetails: baseUrl + '/getAllCaseDetails',
  fetchEmployeeCase: baseUrl + '/fetchEmployeeCase',
  fetchIndivCaseUrl: baseUrl + '/fetchIndividualCase',
  fetchQcFinalComponent: baseUrl + '/fetchQcFinalComponent',
  updateCasesUrl: baseUrl + '/updateCases',
  uploadBulkUrl: baseUrl + '/getBulkUploadUrl',
  downloadFileApi: baseUrl + '/downloadDocument',
  insertClients: baseUrl + '/insertClients',
  fetchAllClients: baseUrl + '/fetchAllClients',
  fetchAllUsers: baseUrl + '/fetchAllUsers',
  fetchIndividualUser: baseUrl + '/fetchIndividualUser',
  fetchIndividualClient: baseUrl + '/fetchIndividualClient',
  updateUser: baseUrl + '/updateUser',
  updateClient: baseUrl + '/updateClient',
  updateTagsForCase: baseUrl + '/updateTagsForCase',
  deleteUser: baseUrl + '/deleteUser',
  deleteClient: baseUrl + '/deleteClient',
  fetchClientByUserId: baseUrl + '/fetchClientByUserId',
  fetchUsersBySupId: baseUrl + '/fetchUsersBySupId',
  insertUsers: baseUrl + '/insertUsers',
  allocateCase: baseUrl + '/allocateCase',
  fetchCasesByUserId: baseUrl + '/fetchCasesByUserId',
  fetchOperationalUserComponent: baseUrl + '/fetchOperationalUserComponent',
  uploadEvidenceUrl: baseUrl + '/uploadEvidence',
  fetchCasesStatusCount: baseUrl + '/fetchCasesStatusCount',
  fetchReportStatusCount: baseUrl + '/fetchReportStatusCount',
  fetchAllocatedUsers: baseUrl + '/fetchAllocatedUsers',
  bulkUpload: baseUrl + '/bulkUpload',
  verifyCase: baseUrl + '/verifyCases',
  fetchDocumentUrl: baseUrl + '/fetchDocument',
  updateReportStatus: baseUrl + '/updateReportStatus',
  // generateReport: generateReportUrl + '/generateReport',
  generateReport: reportURL + '/generateReport',
  sendMailToClientHR: baseUrl + '/sendMailToClientHR',
  convertToSignedUrl: baseUrl + '/convertToSignedUrl',
  downloadReport: baseUrl + '/downloadReport',
  deleteCases: baseUrl + '/deleteCases',
  deleteSelectedComponents: baseUrl + '/deleteSelectedComponents',
  clearInsufficiency: baseUrl + '/clearInsufficiency',
  updateAddressVerificationType: baseUrl + '/updateAddressVerificationType',
  initiateDigitalAddressVerification: baseUrl + '/initiateDigitalAddressVerification',
  reInitiateDigitalAddressVerification: baseUrl + '/reInitiateDigitalAddressVerification',
  verifyIndividualDigitalAddress: baseUrl + '/verifyIndividualDigitalAddress',
  reSendDigitalAddressVerification: baseUrl + '/reSendDigitalAddressVerification',
  fetchDigitalAddress: baseUrl + '/fetchDigitalAddress',
  submitDigitalAddressVerification: baseUrl + '/submitDigitalAddressVerification',
  updateDigitalAddressVerificationStatus: baseUrl + '/updateDigitalAddressVerificationStatus',
  generateDigitalAddressReport: reportURL + '/generateDigitalAddressReport',
  saveDigitalAddressTypes: baseUrl + '/saveDigitalAddressTypes',
  savePhysicalAddressTypes: baseUrl + '/savePhysicalAddressTypes',
  fetchInvoices: baseUrl + '/fetchInvoices',
  updateClientPricing: baseUrl + '/updateClientPricing',
  fetchClientInfoAndConfig: baseUrl + '/fetchClientInfoAndConfig',
  fetchInvoiceOverview: baseUrl + '/fetchInvoiceOverview',
  insertInvoiceOverview: baseUrl + '/insertInvoiceOverview',
  fetchIndividualInvoice: baseUrl + '/fetchIndividualInvoice',
  updateInvoiceType: baseUrl + '/updateInvoiceType',
  generateInvoiceReport: reportURL + '/generateInvoiceReport'
};

export default dynamicUrl;
