import axios from 'axios';
import { SessionStorage } from '../../../../util/SessionStorage';
import { dbMapping } from './dbMapping';
import * as Constants from '../../../../helper/constants';
import dynamicUrl from '../../../../helper/dynamicUrls';
import { isEmptyArray } from '../../../../util/utils';

export const bgvApi = async (url, data, component, case_id, user_client_id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          data: data,
          case_id: case_id,
          component_name: component,
          case_client_id: user_client_id ? user_client_id : ''
        },
        {
          headers: {
            Authorization: SessionStorage.getItem('user_jwt')
          }
        }
      )
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        resolve({ Error: error });
      });
  });
};

export const fetchCaseCompDetails = async (url, case_id, component) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          data: { case_id: case_id }
        },
        {
          headers: {
            Authorization: SessionStorage.getItem('user_jwt')
          }
        }
      )
      .then((response) => {
        console.log(response);
        let allCaseDetail = response.data.Items[0];
        let componentMap = dbMapping[component];
        console.log('componentMap', componentMap);
        let componentData = allCaseDetail[componentMap];
        resolve(componentData);
        // let extras = {};
        // switch (component) {
        //   case 'DirectorshipCheck':
        //     extras.empName = allCaseDetail[dbMapping.BasicDetails].firstName + allCaseDetail[dbMapping.BasicDetails].lastName;
        //     resolve({ componentData: componentData, extras: extras });
        //     console.log('--------- extras', componentData, extras);
        //     break;

        //   default:
        //     resolve(componentData);
        //     break;
        // }
      })
      .catch((error) => {
        console.log(error);
        resolve('Error', error);
      });
  });
};

export const fetchAllCaseDetails = async (url, case_id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          data: { case_id: case_id }
        },
        {
          headers: {
            Authorization: SessionStorage.getItem('user_jwt')
          }
        }
      )
      .then((response) => {
        console.log(response);
        resolve(response.data.Items[0]);
      })
      .catch((error) => {
        console.log(error);
        resolve('Error', error);
      });
  });
};

export const defaultPostApi2 = async (url, case_id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          data: { case_id: case_id }
        },
        {
          headers: {
            Authorization: SessionStorage.getItem('user_jwt')
          }
        }
      )
      .then((response) => {
        console.log(response);
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve('Error', error);
      });
  });
};

export const verifyComponent = async () => {};

export const fetchVerifiers = async (url, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, {
        headers: {
          Authorization: SessionStorage.getItem('user_jwt')
        }
      })
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        resolve({ Error: error });
      });
  });
};

export const defaultPostApi = async (url, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, {
        headers: {
          Authorization: SessionStorage.getItem('user_jwt')
        }
      })
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        resolve({ Error: error });
      });
  });
};

export const logoutApi = async (url, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, {
        headers: {
          Authorization: SessionStorage.getItem('user_jwt')
        }
      })
      .then((response) => {
        console.log(response);
        SessionStorage.clearAll();
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        resolve({ Error: error });
      });
  });
};

export const bulkUploadApi = async (url, data, case_client_id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          data: data,
          case_client_id: case_client_id ? case_client_id : ''
        },
        {
          headers: {
            Authorization: SessionStorage.getItem('user_jwt')
          }
        }
      )
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        resolve({ Error: error });
      });
  });
};

export const fetchAllClientsToSelect = async () => {
  let fetchClientUrl = SessionStorage.getItem("user_category") === Constants.PortalUserRoles.CstUser ? dynamicUrl.fetchClientByUserId : dynamicUrl.fetchAllClients
  const fetchAllClientsToSelectResponse = await defaultPostApi(fetchClientUrl, {});
  if (fetchAllClientsToSelectResponse.Error) {
    console.log(fetchAllClientsToSelectResponse.Error);
  } else {
    console.log("fetchAllClientsToSelectForDropdown", fetchAllClientsToSelectResponse);

    if (!isEmptyArray(fetchAllClientsToSelectResponse.data.Items)) {
      let clientSelectOptions = [{ value: "", label: "All cases", color: "black" }]
      let Clients = fetchAllClientsToSelectResponse.data.Items;
      Clients.map((client, index) => {
        clientSelectOptions.push({ value: client.client_id, label: client.client_name, color: "black" });
      })
      console.log('-------------', clientSelectOptions);
      return (clientSelectOptions);
    }
    else {
      console.log("No clients available");
      return ([]);
    }
  }
}

export const fetchAllClientOptions = async () => {
  let fetchClientUrl = SessionStorage.getItem("user_category") === Constants.PortalUserRoles.CstUser ? dynamicUrl.fetchClientByUserId : dynamicUrl.fetchAllClients
  const fetchAllClientsToSelectResponse = await defaultPostApi(fetchClientUrl, {});
  if (fetchAllClientsToSelectResponse.Error) {
    console.log(fetchAllClientsToSelectResponse.Error);
  } else {
    console.log("fetchAllClientsToSelectForDropdown", fetchAllClientsToSelectResponse);

    if (!isEmptyArray(fetchAllClientsToSelectResponse.data.Items)) {
      let clientSelectOptions = []
      let Clients = fetchAllClientsToSelectResponse.data.Items;
      Clients.map((client, index) => {
        clientSelectOptions.push({ value: client.client_id, label: client.client_name, color: "black" });
      })
      console.log('-------------', clientSelectOptions);
      return (clientSelectOptions);
    }
    else {
      console.log("No clients available");
      return ([]);
    }
  }
}